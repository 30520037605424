const state = {
  model: '',
  printSpeed: {
    min: 0,
    max: 150
  },
  paperSize: '',
  // Empty filter should return all brands
  brand: '',
}

const actions = {
  SET_SPEED: ({ commit }, { target, value }) => {
    if (target === 'min') {
      commit('SET_SPEED_MIN', value)
    } else {
      commit('SET_SPEED_MAX', value)
    }
  },
  SET_MAX_SPEED: ({ commit }, value) => {
    commit('SET_SPEED_MAX', value)
  },
  SET_FILTER_MODEL_NAME: ({ commit }, value) => {
    commit('SET_FILTERMODEL', value)
  },
  SET_FILTER_BRAND: ({ commit }, value) => {
    commit('SET_FILTERBRAND', value)
  }
}

const mutations = {
  SET_SPEED_MIN: (state, value) => {
    state.printSpeed.min = value
  },
  SET_SPEED_MAX: (state, value) => {
    state.printSpeed.max = value
  },
  SET_FILTERMODEL: (state, value) => {
    state.model = value
  },
  SET_FILTERBRAND: (state, value) => {
    state.brand = value
  }
}

const getters = {
  getFilterState: state => state,
  getFilterSpeed: state => state.printSpeed
}

export default { state, mutations, actions, getters }
